@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Broken+Fax&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Funnel+Display:wght@800&display=swap');

html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #5B3F19;
  }